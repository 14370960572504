import { default as _91_91pagenumber_93_93vZqnUMj6MnMeta } from "/app/pages/[slug]-offers/[id]/[[pagenumber]].vue?macro=true";
import { default as indexprURIxAkqqMeta } from "/app/pages/[slug]-offers/index.vue?macro=true";
import { default as indexq38bJr6bNoMeta } from "/app/pages/[slug]/index.vue?macro=true";
import { default as shops26BPm5WnvgMeta } from "/app/pages/[slug]/shops.vue?macro=true";
import { default as indexbUTYpCP6oZMeta } from "/app/pages/app/index.vue?macro=true";
import { default as _91slug_93cdf2dv6FbPMeta } from "/app/pages/blog/[slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91slug_93z5slPNPEhUMeta } from "/app/pages/brands/[slug].vue?macro=true";
import { default as indexooJswcUi1vMeta } from "/app/pages/brands/index.vue?macro=true";
import { default as index51lKbWy4U0Meta } from "/app/pages/categories/[slug]/index.vue?macro=true";
import { default as indexOcsRkjobiDMeta } from "/app/pages/categories/index.vue?macro=true";
import { default as _91slug_93lM4cBooRheMeta } from "/app/pages/cities/[slug].vue?macro=true";
import { default as indexWKuDFIZg1bMeta } from "/app/pages/cities/index.vue?macro=true";
import { default as _91_91pagenumber_93_93Wt4AEG5HzEMeta } from "/app/pages/embed/[id]/[[pagenumber]].vue?macro=true";
import { default as feature_45flagsofWlQsANOfMeta } from "/app/pages/feature-flags.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexiPWDApGVsoMeta } from "/app/pages/new/index.vue?macro=true";
import { default as indexrE58Se8eV6Meta } from "/app/pages/offers/index.vue?macro=true";
import { default as _91referenceId_93a08j9mRIpMMeta } from "/app/pages/search/[searchQuery]/[type]/[referenceId].vue?macro=true";
import { default as index1VVBhUQenwMeta } from "/app/pages/search/[searchQuery]/index.vue?macro=true";
import { default as indexqybDxIRgfkMeta } from "/app/pages/shops/index.vue?macro=true";
export default [
  {
    name: _91_91pagenumber_93_93vZqnUMj6MnMeta?.name ?? "slug-offers-id-pagenumber___nl-NL",
    path: _91_91pagenumber_93_93vZqnUMj6MnMeta?.path ?? "/:slug()-aanbiedingen/:id()/:pagenumber?",
    meta: _91_91pagenumber_93_93vZqnUMj6MnMeta || {},
    alias: _91_91pagenumber_93_93vZqnUMj6MnMeta?.alias || [],
    redirect: _91_91pagenumber_93_93vZqnUMj6MnMeta?.redirect,
    component: () => import("/app/pages/[slug]-offers/[id]/[[pagenumber]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pagenumber_93_93vZqnUMj6MnMeta?.name ?? "slug-offers-id-pagenumber___nl",
    path: _91_91pagenumber_93_93vZqnUMj6MnMeta?.path ?? "/nl/:slug()-folder/:id()/:pagenumber?",
    meta: _91_91pagenumber_93_93vZqnUMj6MnMeta || {},
    alias: _91_91pagenumber_93_93vZqnUMj6MnMeta?.alias || [],
    redirect: _91_91pagenumber_93_93vZqnUMj6MnMeta?.redirect,
    component: () => import("/app/pages/[slug]-offers/[id]/[[pagenumber]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pagenumber_93_93vZqnUMj6MnMeta?.name ?? "slug-offers-id-pagenumber___fr",
    path: _91_91pagenumber_93_93vZqnUMj6MnMeta?.path ?? "/fr/:slug()-folder/:id()/:pagenumber?",
    meta: _91_91pagenumber_93_93vZqnUMj6MnMeta || {},
    alias: _91_91pagenumber_93_93vZqnUMj6MnMeta?.alias || [],
    redirect: _91_91pagenumber_93_93vZqnUMj6MnMeta?.redirect,
    component: () => import("/app/pages/[slug]-offers/[id]/[[pagenumber]].vue").then(m => m.default || m)
  },
  {
    name: indexprURIxAkqqMeta?.name ?? "slug-offers___nl-NL",
    path: indexprURIxAkqqMeta?.path ?? "/:slug()-aanbiedingen",
    meta: indexprURIxAkqqMeta || {},
    alias: indexprURIxAkqqMeta?.alias || [],
    redirect: indexprURIxAkqqMeta?.redirect,
    component: () => import("/app/pages/[slug]-offers/index.vue").then(m => m.default || m)
  },
  {
    name: indexprURIxAkqqMeta?.name ?? "slug-offers___nl",
    path: indexprURIxAkqqMeta?.path ?? "/nl/:slug()-folder",
    meta: indexprURIxAkqqMeta || {},
    alias: indexprURIxAkqqMeta?.alias || [],
    redirect: indexprURIxAkqqMeta?.redirect,
    component: () => import("/app/pages/[slug]-offers/index.vue").then(m => m.default || m)
  },
  {
    name: indexprURIxAkqqMeta?.name ?? "slug-offers___fr",
    path: indexprURIxAkqqMeta?.path ?? "/fr/:slug()-folder",
    meta: indexprURIxAkqqMeta || {},
    alias: indexprURIxAkqqMeta?.alias || [],
    redirect: indexprURIxAkqqMeta?.redirect,
    component: () => import("/app/pages/[slug]-offers/index.vue").then(m => m.default || m)
  },
  {
    name: indexq38bJr6bNoMeta?.name ?? "slug___nl-NL",
    path: indexq38bJr6bNoMeta?.path ?? "/:slug()",
    meta: indexq38bJr6bNoMeta || {},
    alias: indexq38bJr6bNoMeta?.alias || [],
    redirect: indexq38bJr6bNoMeta?.redirect,
    component: () => import("/app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexq38bJr6bNoMeta?.name ?? "slug___nl",
    path: indexq38bJr6bNoMeta?.path ?? "/nl/:slug()",
    meta: indexq38bJr6bNoMeta || {},
    alias: indexq38bJr6bNoMeta?.alias || [],
    redirect: indexq38bJr6bNoMeta?.redirect,
    component: () => import("/app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexq38bJr6bNoMeta?.name ?? "slug___fr",
    path: indexq38bJr6bNoMeta?.path ?? "/fr/:slug()",
    meta: indexq38bJr6bNoMeta || {},
    alias: indexq38bJr6bNoMeta?.alias || [],
    redirect: indexq38bJr6bNoMeta?.redirect,
    component: () => import("/app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: shops26BPm5WnvgMeta?.name ?? "slug-shops___nl-NL",
    path: shops26BPm5WnvgMeta?.path ?? "/:slug()/folder-aanbiedingen",
    meta: shops26BPm5WnvgMeta || {},
    alias: shops26BPm5WnvgMeta?.alias || [],
    redirect: shops26BPm5WnvgMeta?.redirect,
    component: () => import("/app/pages/[slug]/shops.vue").then(m => m.default || m)
  },
  {
    name: shops26BPm5WnvgMeta?.name ?? "slug-shops___nl",
    path: shops26BPm5WnvgMeta?.path ?? "/nl/:slug()/folder-aanbiedingen",
    meta: shops26BPm5WnvgMeta || {},
    alias: shops26BPm5WnvgMeta?.alias || [],
    redirect: shops26BPm5WnvgMeta?.redirect,
    component: () => import("/app/pages/[slug]/shops.vue").then(m => m.default || m)
  },
  {
    name: shops26BPm5WnvgMeta?.name ?? "slug-shops___fr",
    path: shops26BPm5WnvgMeta?.path ?? "/fr/:slug()/foldre-offres",
    meta: shops26BPm5WnvgMeta || {},
    alias: shops26BPm5WnvgMeta?.alias || [],
    redirect: shops26BPm5WnvgMeta?.redirect,
    component: () => import("/app/pages/[slug]/shops.vue").then(m => m.default || m)
  },
  {
    name: indexbUTYpCP6oZMeta?.name ?? "app___nl-NL",
    path: indexbUTYpCP6oZMeta?.path ?? "/app",
    meta: indexbUTYpCP6oZMeta || {},
    alias: indexbUTYpCP6oZMeta?.alias || [],
    redirect: indexbUTYpCP6oZMeta?.redirect,
    component: () => import("/app/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: indexbUTYpCP6oZMeta?.name ?? "app___nl",
    path: indexbUTYpCP6oZMeta?.path ?? "/nl/app",
    meta: indexbUTYpCP6oZMeta || {},
    alias: indexbUTYpCP6oZMeta?.alias || [],
    redirect: indexbUTYpCP6oZMeta?.redirect,
    component: () => import("/app/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: indexbUTYpCP6oZMeta?.name ?? "app___fr",
    path: indexbUTYpCP6oZMeta?.path ?? "/fr/app",
    meta: indexbUTYpCP6oZMeta || {},
    alias: indexbUTYpCP6oZMeta?.alias || [],
    redirect: indexbUTYpCP6oZMeta?.redirect,
    component: () => import("/app/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cdf2dv6FbPMeta?.name ?? "blog-slug___nl-NL",
    path: _91slug_93cdf2dv6FbPMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93cdf2dv6FbPMeta || {},
    alias: _91slug_93cdf2dv6FbPMeta?.alias || [],
    redirect: _91slug_93cdf2dv6FbPMeta?.redirect,
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cdf2dv6FbPMeta?.name ?? "blog-slug___nl",
    path: _91slug_93cdf2dv6FbPMeta?.path ?? "/nl/blog/:slug()",
    meta: _91slug_93cdf2dv6FbPMeta || {},
    alias: _91slug_93cdf2dv6FbPMeta?.alias || [],
    redirect: _91slug_93cdf2dv6FbPMeta?.redirect,
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cdf2dv6FbPMeta?.name ?? "blog-slug___fr",
    path: _91slug_93cdf2dv6FbPMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_93cdf2dv6FbPMeta || {},
    alias: _91slug_93cdf2dv6FbPMeta?.alias || [],
    redirect: _91slug_93cdf2dv6FbPMeta?.redirect,
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgWaMlevJ7RMeta?.name ?? "blog___nl-NL",
    path: indexgWaMlevJ7RMeta?.path ?? "/blog",
    meta: indexgWaMlevJ7RMeta || {},
    alias: indexgWaMlevJ7RMeta?.alias || [],
    redirect: indexgWaMlevJ7RMeta?.redirect,
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexgWaMlevJ7RMeta?.name ?? "blog___nl",
    path: indexgWaMlevJ7RMeta?.path ?? "/nl/blog",
    meta: indexgWaMlevJ7RMeta || {},
    alias: indexgWaMlevJ7RMeta?.alias || [],
    redirect: indexgWaMlevJ7RMeta?.redirect,
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexgWaMlevJ7RMeta?.name ?? "blog___fr",
    path: indexgWaMlevJ7RMeta?.path ?? "/fr/blog",
    meta: indexgWaMlevJ7RMeta || {},
    alias: indexgWaMlevJ7RMeta?.alias || [],
    redirect: indexgWaMlevJ7RMeta?.redirect,
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z5slPNPEhUMeta?.name ?? "brands-slug___nl-NL",
    path: _91slug_93z5slPNPEhUMeta?.path ?? "/merken/:slug()",
    meta: _91slug_93z5slPNPEhUMeta || {},
    alias: _91slug_93z5slPNPEhUMeta?.alias || [],
    redirect: _91slug_93z5slPNPEhUMeta?.redirect,
    component: () => import("/app/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z5slPNPEhUMeta?.name ?? "brands-slug___nl",
    path: _91slug_93z5slPNPEhUMeta?.path ?? "/nl/merken/:slug()",
    meta: _91slug_93z5slPNPEhUMeta || {},
    alias: _91slug_93z5slPNPEhUMeta?.alias || [],
    redirect: _91slug_93z5slPNPEhUMeta?.redirect,
    component: () => import("/app/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z5slPNPEhUMeta?.name ?? "brands-slug___fr",
    path: _91slug_93z5slPNPEhUMeta?.path ?? "/fr/marques/:slug()",
    meta: _91slug_93z5slPNPEhUMeta || {},
    alias: _91slug_93z5slPNPEhUMeta?.alias || [],
    redirect: _91slug_93z5slPNPEhUMeta?.redirect,
    component: () => import("/app/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexooJswcUi1vMeta?.name ?? "brands___nl-NL",
    path: indexooJswcUi1vMeta?.path ?? "/merken",
    meta: indexooJswcUi1vMeta || {},
    alias: indexooJswcUi1vMeta?.alias || [],
    redirect: indexooJswcUi1vMeta?.redirect,
    component: () => import("/app/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: indexooJswcUi1vMeta?.name ?? "brands___nl",
    path: indexooJswcUi1vMeta?.path ?? "/nl/merken",
    meta: indexooJswcUi1vMeta || {},
    alias: indexooJswcUi1vMeta?.alias || [],
    redirect: indexooJswcUi1vMeta?.redirect,
    component: () => import("/app/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: indexooJswcUi1vMeta?.name ?? "brands___fr",
    path: indexooJswcUi1vMeta?.path ?? "/fr/marques",
    meta: indexooJswcUi1vMeta || {},
    alias: indexooJswcUi1vMeta?.alias || [],
    redirect: indexooJswcUi1vMeta?.redirect,
    component: () => import("/app/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: index51lKbWy4U0Meta?.name ?? "categories-slug___nl-NL",
    path: index51lKbWy4U0Meta?.path ?? "/categorieen/:slug()",
    meta: index51lKbWy4U0Meta || {},
    alias: index51lKbWy4U0Meta?.alias || [],
    redirect: index51lKbWy4U0Meta?.redirect,
    component: () => import("/app/pages/categories/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index51lKbWy4U0Meta?.name ?? "categories-slug___nl",
    path: index51lKbWy4U0Meta?.path ?? "/nl/categorieen/:slug()",
    meta: index51lKbWy4U0Meta || {},
    alias: index51lKbWy4U0Meta?.alias || [],
    redirect: index51lKbWy4U0Meta?.redirect,
    component: () => import("/app/pages/categories/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index51lKbWy4U0Meta?.name ?? "categories-slug___fr",
    path: index51lKbWy4U0Meta?.path ?? "/fr/categories/:slug()",
    meta: index51lKbWy4U0Meta || {},
    alias: index51lKbWy4U0Meta?.alias || [],
    redirect: index51lKbWy4U0Meta?.redirect,
    component: () => import("/app/pages/categories/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOcsRkjobiDMeta?.name ?? "categories___nl-NL",
    path: indexOcsRkjobiDMeta?.path ?? "/categorieen",
    meta: indexOcsRkjobiDMeta || {},
    alias: indexOcsRkjobiDMeta?.alias || [],
    redirect: indexOcsRkjobiDMeta?.redirect,
    component: () => import("/app/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexOcsRkjobiDMeta?.name ?? "categories___nl",
    path: indexOcsRkjobiDMeta?.path ?? "/nl/categorieen",
    meta: indexOcsRkjobiDMeta || {},
    alias: indexOcsRkjobiDMeta?.alias || [],
    redirect: indexOcsRkjobiDMeta?.redirect,
    component: () => import("/app/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexOcsRkjobiDMeta?.name ?? "categories___fr",
    path: indexOcsRkjobiDMeta?.path ?? "/fr/categories",
    meta: indexOcsRkjobiDMeta || {},
    alias: indexOcsRkjobiDMeta?.alias || [],
    redirect: indexOcsRkjobiDMeta?.redirect,
    component: () => import("/app/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lM4cBooRheMeta?.name ?? "cities-slug___nl-NL",
    path: _91slug_93lM4cBooRheMeta?.path ?? "/steden/:slug()",
    meta: _91slug_93lM4cBooRheMeta || {},
    alias: _91slug_93lM4cBooRheMeta?.alias || [],
    redirect: _91slug_93lM4cBooRheMeta?.redirect,
    component: () => import("/app/pages/cities/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lM4cBooRheMeta?.name ?? "cities-slug___nl",
    path: _91slug_93lM4cBooRheMeta?.path ?? "/nl/steden/:slug()",
    meta: _91slug_93lM4cBooRheMeta || {},
    alias: _91slug_93lM4cBooRheMeta?.alias || [],
    redirect: _91slug_93lM4cBooRheMeta?.redirect,
    component: () => import("/app/pages/cities/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lM4cBooRheMeta?.name ?? "cities-slug___fr",
    path: _91slug_93lM4cBooRheMeta?.path ?? "/fr/villes/:slug()",
    meta: _91slug_93lM4cBooRheMeta || {},
    alias: _91slug_93lM4cBooRheMeta?.alias || [],
    redirect: _91slug_93lM4cBooRheMeta?.redirect,
    component: () => import("/app/pages/cities/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexWKuDFIZg1bMeta?.name ?? "cities___nl-NL",
    path: indexWKuDFIZg1bMeta?.path ?? "/steden",
    meta: indexWKuDFIZg1bMeta || {},
    alias: indexWKuDFIZg1bMeta?.alias || [],
    redirect: indexWKuDFIZg1bMeta?.redirect,
    component: () => import("/app/pages/cities/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKuDFIZg1bMeta?.name ?? "cities___nl",
    path: indexWKuDFIZg1bMeta?.path ?? "/nl/steden",
    meta: indexWKuDFIZg1bMeta || {},
    alias: indexWKuDFIZg1bMeta?.alias || [],
    redirect: indexWKuDFIZg1bMeta?.redirect,
    component: () => import("/app/pages/cities/index.vue").then(m => m.default || m)
  },
  {
    name: indexWKuDFIZg1bMeta?.name ?? "cities___fr",
    path: indexWKuDFIZg1bMeta?.path ?? "/fr/villes",
    meta: indexWKuDFIZg1bMeta || {},
    alias: indexWKuDFIZg1bMeta?.alias || [],
    redirect: indexWKuDFIZg1bMeta?.redirect,
    component: () => import("/app/pages/cities/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91pagenumber_93_93Wt4AEG5HzEMeta?.name ?? "embed-id-pagenumber___nl-NL",
    path: _91_91pagenumber_93_93Wt4AEG5HzEMeta?.path ?? "/embed/:id()/:pagenumber?",
    meta: _91_91pagenumber_93_93Wt4AEG5HzEMeta || {},
    alias: _91_91pagenumber_93_93Wt4AEG5HzEMeta?.alias || [],
    redirect: _91_91pagenumber_93_93Wt4AEG5HzEMeta?.redirect,
    component: () => import("/app/pages/embed/[id]/[[pagenumber]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pagenumber_93_93Wt4AEG5HzEMeta?.name ?? "embed-id-pagenumber___nl",
    path: _91_91pagenumber_93_93Wt4AEG5HzEMeta?.path ?? "/nl/embed/:id()/:pagenumber?",
    meta: _91_91pagenumber_93_93Wt4AEG5HzEMeta || {},
    alias: _91_91pagenumber_93_93Wt4AEG5HzEMeta?.alias || [],
    redirect: _91_91pagenumber_93_93Wt4AEG5HzEMeta?.redirect,
    component: () => import("/app/pages/embed/[id]/[[pagenumber]].vue").then(m => m.default || m)
  },
  {
    name: _91_91pagenumber_93_93Wt4AEG5HzEMeta?.name ?? "embed-id-pagenumber___fr",
    path: _91_91pagenumber_93_93Wt4AEG5HzEMeta?.path ?? "/fr/embed/:id()/:pagenumber?",
    meta: _91_91pagenumber_93_93Wt4AEG5HzEMeta || {},
    alias: _91_91pagenumber_93_93Wt4AEG5HzEMeta?.alias || [],
    redirect: _91_91pagenumber_93_93Wt4AEG5HzEMeta?.redirect,
    component: () => import("/app/pages/embed/[id]/[[pagenumber]].vue").then(m => m.default || m)
  },
  {
    name: feature_45flagsofWlQsANOfMeta?.name ?? "feature-flags___nl-NL",
    path: feature_45flagsofWlQsANOfMeta?.path ?? "/feature-flags",
    meta: feature_45flagsofWlQsANOfMeta || {},
    alias: feature_45flagsofWlQsANOfMeta?.alias || [],
    redirect: feature_45flagsofWlQsANOfMeta?.redirect,
    component: () => import("/app/pages/feature-flags.vue").then(m => m.default || m)
  },
  {
    name: feature_45flagsofWlQsANOfMeta?.name ?? "feature-flags___nl",
    path: feature_45flagsofWlQsANOfMeta?.path ?? "/nl/feature-flags",
    meta: feature_45flagsofWlQsANOfMeta || {},
    alias: feature_45flagsofWlQsANOfMeta?.alias || [],
    redirect: feature_45flagsofWlQsANOfMeta?.redirect,
    component: () => import("/app/pages/feature-flags.vue").then(m => m.default || m)
  },
  {
    name: feature_45flagsofWlQsANOfMeta?.name ?? "feature-flags___fr",
    path: feature_45flagsofWlQsANOfMeta?.path ?? "/fr/feature-flags",
    meta: feature_45flagsofWlQsANOfMeta || {},
    alias: feature_45flagsofWlQsANOfMeta?.alias || [],
    redirect: feature_45flagsofWlQsANOfMeta?.redirect,
    component: () => import("/app/pages/feature-flags.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___nl-NL",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___nl",
    path: indexN6pT4Un8hYMeta?.path ?? "/nl",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___fr",
    path: indexN6pT4Un8hYMeta?.path ?? "/fr",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexiPWDApGVsoMeta?.name ?? "new___nl-NL",
    path: indexiPWDApGVsoMeta?.path ?? "/nieuw",
    meta: indexiPWDApGVsoMeta || {},
    alias: indexiPWDApGVsoMeta?.alias || [],
    redirect: indexiPWDApGVsoMeta?.redirect,
    component: () => import("/app/pages/new/index.vue").then(m => m.default || m)
  },
  {
    name: indexiPWDApGVsoMeta?.name ?? "new___nl",
    path: indexiPWDApGVsoMeta?.path ?? "/nl/nieuw",
    meta: indexiPWDApGVsoMeta || {},
    alias: indexiPWDApGVsoMeta?.alias || [],
    redirect: indexiPWDApGVsoMeta?.redirect,
    component: () => import("/app/pages/new/index.vue").then(m => m.default || m)
  },
  {
    name: indexiPWDApGVsoMeta?.name ?? "new___fr",
    path: indexiPWDApGVsoMeta?.path ?? "/fr/nouveaux",
    meta: indexiPWDApGVsoMeta || {},
    alias: indexiPWDApGVsoMeta?.alias || [],
    redirect: indexiPWDApGVsoMeta?.redirect,
    component: () => import("/app/pages/new/index.vue").then(m => m.default || m)
  },
  {
    name: indexrE58Se8eV6Meta?.name ?? "offers___nl-NL",
    path: indexrE58Se8eV6Meta?.path ?? "/aanbiedingen",
    meta: indexrE58Se8eV6Meta || {},
    alias: indexrE58Se8eV6Meta?.alias || [],
    redirect: indexrE58Se8eV6Meta?.redirect,
    component: () => import("/app/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: indexrE58Se8eV6Meta?.name ?? "offers___nl",
    path: indexrE58Se8eV6Meta?.path ?? "/nl/aanbiedingen",
    meta: indexrE58Se8eV6Meta || {},
    alias: indexrE58Se8eV6Meta?.alias || [],
    redirect: indexrE58Se8eV6Meta?.redirect,
    component: () => import("/app/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: indexrE58Se8eV6Meta?.name ?? "offers___fr",
    path: indexrE58Se8eV6Meta?.path ?? "/fr/offres",
    meta: indexrE58Se8eV6Meta || {},
    alias: indexrE58Se8eV6Meta?.alias || [],
    redirect: indexrE58Se8eV6Meta?.redirect,
    component: () => import("/app/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: _91referenceId_93a08j9mRIpMMeta?.name ?? "search-searchQuery-type-referenceId___nl-NL",
    path: _91referenceId_93a08j9mRIpMMeta?.path ?? "/zoekresultaten/:searchQuery()/:type()/:referenceId()",
    meta: _91referenceId_93a08j9mRIpMMeta || {},
    alias: _91referenceId_93a08j9mRIpMMeta?.alias || [],
    redirect: _91referenceId_93a08j9mRIpMMeta?.redirect,
    component: () => import("/app/pages/search/[searchQuery]/[type]/[referenceId].vue").then(m => m.default || m)
  },
  {
    name: _91referenceId_93a08j9mRIpMMeta?.name ?? "search-searchQuery-type-referenceId___nl",
    path: _91referenceId_93a08j9mRIpMMeta?.path ?? "/nl/zoekresultaten/:searchQuery()/:type()/:referenceId()",
    meta: _91referenceId_93a08j9mRIpMMeta || {},
    alias: _91referenceId_93a08j9mRIpMMeta?.alias || [],
    redirect: _91referenceId_93a08j9mRIpMMeta?.redirect,
    component: () => import("/app/pages/search/[searchQuery]/[type]/[referenceId].vue").then(m => m.default || m)
  },
  {
    name: _91referenceId_93a08j9mRIpMMeta?.name ?? "search-searchQuery-type-referenceId___fr",
    path: _91referenceId_93a08j9mRIpMMeta?.path ?? "/fr/resultatdecherche/:searchQuery()/:type()/:referenceId()",
    meta: _91referenceId_93a08j9mRIpMMeta || {},
    alias: _91referenceId_93a08j9mRIpMMeta?.alias || [],
    redirect: _91referenceId_93a08j9mRIpMMeta?.redirect,
    component: () => import("/app/pages/search/[searchQuery]/[type]/[referenceId].vue").then(m => m.default || m)
  },
  {
    name: index1VVBhUQenwMeta?.name ?? "search-searchQuery___nl-NL",
    path: index1VVBhUQenwMeta?.path ?? "/zoekresultaten/:searchQuery()",
    meta: index1VVBhUQenwMeta || {},
    alias: index1VVBhUQenwMeta?.alias || [],
    redirect: index1VVBhUQenwMeta?.redirect,
    component: () => import("/app/pages/search/[searchQuery]/index.vue").then(m => m.default || m)
  },
  {
    name: index1VVBhUQenwMeta?.name ?? "search-searchQuery___nl",
    path: index1VVBhUQenwMeta?.path ?? "/nl/zoekresultaten/:searchQuery()",
    meta: index1VVBhUQenwMeta || {},
    alias: index1VVBhUQenwMeta?.alias || [],
    redirect: index1VVBhUQenwMeta?.redirect,
    component: () => import("/app/pages/search/[searchQuery]/index.vue").then(m => m.default || m)
  },
  {
    name: index1VVBhUQenwMeta?.name ?? "search-searchQuery___fr",
    path: index1VVBhUQenwMeta?.path ?? "/fr/resultatdecherche/:searchQuery()",
    meta: index1VVBhUQenwMeta || {},
    alias: index1VVBhUQenwMeta?.alias || [],
    redirect: index1VVBhUQenwMeta?.redirect,
    component: () => import("/app/pages/search/[searchQuery]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqybDxIRgfkMeta?.name ?? "shops___nl-NL",
    path: indexqybDxIRgfkMeta?.path ?? "/winkels",
    meta: indexqybDxIRgfkMeta || {},
    alias: indexqybDxIRgfkMeta?.alias || [],
    redirect: indexqybDxIRgfkMeta?.redirect,
    component: () => import("/app/pages/shops/index.vue").then(m => m.default || m)
  },
  {
    name: indexqybDxIRgfkMeta?.name ?? "shops___nl",
    path: indexqybDxIRgfkMeta?.path ?? "/nl/winkels",
    meta: indexqybDxIRgfkMeta || {},
    alias: indexqybDxIRgfkMeta?.alias || [],
    redirect: indexqybDxIRgfkMeta?.redirect,
    component: () => import("/app/pages/shops/index.vue").then(m => m.default || m)
  },
  {
    name: indexqybDxIRgfkMeta?.name ?? "shops___fr",
    path: indexqybDxIRgfkMeta?.path ?? "/fr/magasins",
    meta: indexqybDxIRgfkMeta || {},
    alias: indexqybDxIRgfkMeta?.alias || [],
    redirect: indexqybDxIRgfkMeta?.redirect,
    component: () => import("/app/pages/shops/index.vue").then(m => m.default || m)
  }
]